<template>
	<svg :width="width" :height="height" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M431.2 103H169.28C162.18 102.978 155.162 104.514 148.72 107.5C142.279 110.486 136.571 114.848 132 120.28L43.5195 224.92C36.0805 233.738 32 244.903 32 256.44C32 267.977 36.0805 279.142 43.5195 287.96L132 392.6C136.571 398.032 142.279 402.395 148.72 405.381C155.162 408.366 162.18 409.902 169.28 409.88H431.2C444.135 409.856 456.533 404.707 465.68 395.56C474.826 386.414 479.975 374.015 480 361.08V151.8C479.975 138.865 474.826 126.467 465.68 117.32C456.533 108.174 444.135 103.025 431.2 103ZM356.64 297.4C359.638 300.421 361.32 304.504 361.32 308.76C361.32 313.016 359.638 317.099 356.64 320.12C353.597 323.08 349.524 324.743 345.28 324.76C341.041 324.713 336.979 323.054 333.92 320.12L292.96 279.16L252 320.12C250.508 321.602 248.739 322.774 246.794 323.572C244.848 324.37 242.765 324.777 240.663 324.769C238.561 324.762 236.48 324.34 234.541 323.529C232.601 322.717 230.841 321.532 229.359 320.04C227.878 318.548 226.705 316.779 225.908 314.834C225.11 312.889 224.703 310.806 224.711 308.704C224.718 306.601 225.139 304.521 225.951 302.582C226.762 300.642 227.948 298.882 229.44 297.4L270.4 256.44L229.44 215.48C226.442 212.459 224.76 208.376 224.76 204.12C224.76 199.864 226.442 195.781 229.44 192.76C230.92 191.277 232.678 190.1 234.613 189.297C236.549 188.494 238.624 188.08 240.72 188.08C242.815 188.08 244.89 188.494 246.826 189.297C248.761 190.1 250.519 191.277 252 192.76L292.96 233.72L333.92 192.76C335.409 191.258 337.181 190.066 339.132 189.25C341.084 188.435 343.178 188.013 345.293 188.008C347.408 188.004 349.504 188.417 351.459 189.225C353.414 190.032 355.19 191.218 356.686 192.714C358.182 194.209 359.367 195.986 360.175 197.941C360.982 199.896 361.396 201.991 361.391 204.107C361.387 206.222 360.965 208.316 360.15 210.267C359.334 212.219 358.141 213.991 356.64 215.48L315.68 256.44L356.64 297.4Z" fill="white"/>
			<rect x="215" y="168.44" width="182" height="176" fill="white"/>
			<g clip-path="url(#clip0_476_55)">
				<path d="M237.082 324.14C233.698 324.337 230.371 323.212 227.801 321.003C222.733 315.905 222.733 307.671 227.801 302.572L338.778 191.596C344.049 186.663 352.321 186.937 357.253 192.209C361.714 196.975 361.974 204.302 357.862 209.373L246.231 321.003C243.695 323.181 240.421 324.303 237.082 324.14Z" fill="black"/>
				<path d="M347.925 324.131C344.496 324.117 341.209 322.756 338.775 320.341L227.798 209.364C223.102 203.88 223.741 195.629 229.224 190.933C234.118 186.742 241.335 186.742 246.229 190.933L357.859 301.91C363.129 306.844 363.402 315.116 358.468 320.386C358.271 320.595 358.069 320.798 357.859 320.994C355.126 323.371 351.528 324.507 347.925 324.131Z" fill="black"/>
			</g>
			<defs>
				<clipPath id="clip0_476_55">
					<rect width="138" height="138" fill="white" transform="translate(224 187)"/>
				</clipPath>
			</defs>
	</svg>

</template>

<script>
export default {
	name: "BackspaceIcon",
	props: {
		height: {
			type: Number,
			default: 24
		},
		width: {
			type: Number,
			default: 24
		}
  	}
}
</script>
